import React, { useState } from 'react';

import './App.css';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
const App = () => (
  <div className="App">
    <Header className="header" />
    <div className="main-content">
      <section id="home"><Home /></section>
      <section id="destinations"><Services /></section>
      <section id="packages"><Technologies /></section>
      <section id="about-us"><AboutUs /></section>
      <section id="process"><Process /></section>
      {/* <section id="testimonials"><Testimonials /></section> */}
      <section id="contact"><Contact /></section>
    </div>
    <Footer />
  </div>
);






const Header = () => {
  const [isNavVisible, setNavVisible] = useState(false);

const toggleNavVisibility = () => {
  setNavVisible(!isNavVisible);
};

const hideNav = () => {
  setNavVisible(false);
};

  return (
    <header className="header">
      <div className="logo-container">
        <img src="/logo.png" alt="RAK Analytics Logo" className="logo" />
        <h1 className="title">
          <span>HariOm</span>
          <span>Enterprises</span>
        </h1>
      </div>
      <div className="hamburger" onClick={toggleNavVisibility}>
  &#9776; {/* Hamburger icon */}
</div>
<nav className={`nav ${isNavVisible ? 'show' : ''}`}>
  <ul>
  <li><a href="#home" onClick={hideNav}>Home</a></li>
<li><a href="#destinations" onClick={hideNav}>Destinations</a></li>
<li><a href="#packages" onClick={hideNav}>Packages</a></li>
<li><a href="#about" onClick={hideNav}>About Us</a></li>
<li><a href="#tours" onClick={hideNav}>Tours</a></li>
{/* <li><a href="#testimonials" onClick={hideNav}>Testimonials</a></li> */}
<li><a href="#contact" onClick={hideNav}>Contact</a></li>

  </ul>
</nav>
    </header>
  );
};




const Home = () => (
  <section id="home" className="home">
    <ImageSlider />
    <div className="home-content">
    <h1>Welcome to Our Car Travel Enterprise</h1>
<p>We offer premium car travel experiences, tailored to your journey needs and preferences.</p>

      <button className="cta-button">Get Started</button>
    </div>
  </section>
);

const ImageSlider = () => {
  return (
    <Carousel
      showThumbs={false}
      autoPlay
      infiniteLoop
      interval={3000}
      showArrows={false}
      showStatus={false}
    >
      <div>
        <img src="/images001.jpg" alt="Software development" className="slider-image" />
      </div>
      <div>
        <img src="/images002.jpg" alt="Coding" className="slider-image" />
      </div>
      <div>
        <img src="/images003.jpg" alt="Technology" className="slider-image" />
      </div>
      <div>
        <img src="/images004.jpg" alt="Cloud computing" className="slider-image" />
      </div>
    </Carousel>
  );
};
const Services = () => (
  <section id="destinations" className="services">
   <h2>Discover Tamil Nadu</h2>
<div className="service-list">
    <div className="service-item">
        <h3>Scenic Hill Stations</h3>
        <p>Explore the breathtaking hill stations of Tamil Nadu, including Ooty, Kodaikanal, and Yercaud, with picturesque drives and stunning views.</p>
    </div>
    <div className="service-item">
        <h3>Cultural Heritage Sites</h3>
        <p>Visit the rich cultural heritage sites like Mahabalipuram, Thanjavur, and Madurai, and experience the vibrant history and architecture of Tamil Nadu.</p>
    </div>
    <div className="service-item">
        <h3>Coastal Retreats</h3>
        <p>Enjoy the beautiful coastal destinations such as Rameswaram, Kanyakumari, and Chennai's pristine beaches, perfect for a relaxing getaway.</p>
    </div>
    <div className="service-item">
        <h3>Wildlife Sanctuaries</h3>
        <p>Discover Tamil Nadu’s wildlife sanctuaries like Mudumalai and Anamalai, offering exciting safari experiences and nature walks.</p>
    </div>
</div>

    
  </section>
);

const Technologies = () => (
  <section id="packages" className="technologies">
    <h2>Our Travel Packages</h2>
<div className="tech-icons">
    <div className="tech-item">Premium Sedans Package</div>
    <div className="tech-item">Executive Car Package</div>
    <div className="tech-item">Family Road Trip Package</div>
    <div className="tech-item">Weekend Getaway Package</div>
    <div className="tech-item">Adventure Travel Package</div>
    <div className="tech-item">Custom Itinerary Package</div>
</div>

  </section>
);

const AboutUs = () => (
  <section id="about" className="about">
    <h2>About Us</h2>
    <p>We are a team of dedicated travel experts passionate about providing exceptional car travel experiences. Our mission is to offer personalized and luxurious travel solutions, ensuring that every journey is memorable and tailored to your needs. With our extensive fleet and commitment to excellence, we help you explore new destinations with comfort and style.</p>

  </section>
);

const Process = () => (
  <section id="tours" className="process">
    <h2>Tours</h2>
    <div className="step">
    <h3>1. Explore Chennai</h3>
    <p>Begin your journey in Chennai, the vibrant capital of Tamil Nadu, where you can visit historic landmarks like the Fort St. George and Marina Beach.</p>
</div>
<div className="step">
    <h3>2. Discover Mahabalipuram</h3>
    <p>Travel to Mahabalipuram to admire the ancient rock-cut temples and the Shore Temple, a UNESCO World Heritage Site.</p>
</div>
<div className="step">
    <h3>3. Experience Pondicherry</h3>
    <p>Explore the charming coastal town of Pondicherry, known for its French colonial architecture, serene beaches, and the Aurobindo Ashram.</p>
</div>
<div className="step">
    <h3>4. Visit Kanchipuram</h3>
    <p>Discover Kanchipuram, the city of thousand temples, famous for its beautiful silk sarees and ancient temples like the Kailasanathar Temple.</p>
</div>
<div className="step">
    <h3>5. Enjoy Ooty</h3>
    <p>Head to Ooty, the picturesque hill station in the Nilgiris, to enjoy its pleasant climate, lush green landscapes, and attractions like the Botanical Gardens and Ooty Lake.</p>
</div>


  </section>
);

// const Testimonials = () => (
//   <section id="testimonials" className="testimonials">
//     <h2>Testimonials</h2>
//     <div className="testimonial-list">
//       <div className="testimonial-item">
//         <p>"The team at Software Service Provider delivered a solution that exceeded our expectations. Their professionalism and expertise are unmatched!"</p>
//         <h4>Client A</h4>
//       </div>
//       <div className="testimonial-item">
//         <p>"Their ability to understand our requirements and provide a tailored solution was impressive. We highly recommend their services."</p>
//         <h4>Client B</h4>
//       </div>
//     </div>
//   </section>
// );

const Contact = () => (
  <section id="contact" className="contact">
    <h2>Contact Us</h2>
    <p>If you're interested in working with us or have any questions, feel free to reach out.</p>
    <p>Mobile no 9361578952,9004756675</p>
    <p>Mail to travel@hariombusinessenterprise.com</p>
    {/* <p>Near 95/60, Cave Temple Rd,opp. to Bgs National Public School, Muthurayya Swamy Layout,Hulimavu,Bengalore,Karnataka</p> */}
  </section>
);

const Footer = () => (
  <footer className="footer">
    <p>&copy; 2024 Car Travel Enterprise. All Rights Reserved.</p>

  </footer>
);

export default App;
